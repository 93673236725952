//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CourseList from '@/components/CourseList'
import ActionButtons from '@/components/Profile/Settings/ActionButtons'
export default {
    props: ['dataset'],
    components: {
        CourseList,
        ActionButtons
    },
    data() {
        return {
            course_id: this.dataset.course_id
        }
    },
    computed: {
        // The script to determine whether to unlock the save button.
        enableSave() {
            if (this.course_id != this.dataset.course_id) {
                return false;
            }

            return true;
        }
    },
    methods: {
        reset() {
            this.course_id = this.dataset.course_id
        },
        setCourseId(course_id) {
            this.course_id = course_id;
        }
    }
}
